<app-header />
<main class="main">
  <app-dev-overlay
    (logout)="this.authService.logout('User Logout from DevOverlayComponent')"
    (validate)="this.authService.validateToken()" />
  <router-outlet />
  @if (this.isAuthenticated()) {
    <app-paperless-billing-modal />
  }
</main>
<app-footer />
