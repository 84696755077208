import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  ANONYMOUS,
  AUTH_TOKEN_OVERRIDE,
} from '../../core/interceptors/auth/tokens';
import {
  LOGGER_FACTORY,
  LoggerFactory,
} from '../../core/observability/provider';
import { isNullEmptyOrWhitespace } from '../../helpers/string';
import { BrowserService } from '../browser/browser.service';
import { ClientSettingsService } from '../client-settings/client-settings.service';
import { VirtualSiteType } from '../virtual-site/virtual-site.models';
import { MDB_VIRTUAL_SITE } from '../virtual-site/virtual-site.service';

import { AuthStateService, AuthStatusType } from './auth-state.service';
import { AuthStorageService } from './auth-storage.service';
import { AuthToken, MdbJwtPayload } from './models';

export interface authPostResponse {
  isSuccess?: boolean;
  error?: string;
  token?: string;
  needsBillingGroup?: boolean;
}

export interface authPatchResponse {
  isSuccess: boolean;
  error: string;
  newToken: string;
}

export interface authenticationByCodeRequest {
  verificationCode: string;
  destination: string;
  dobMonth: string;
  dobDay: string;
  dobYear: string;
  siteUrl: string;
}

export interface authenticationByCodeResponse {
  isSuccess: boolean;
  error: string;
  token: string;
  needsBillingGroup: boolean;
}

export interface validateTokenResponse {
  success: boolean;
  token: string;
  accountNumber: number | '';
  needsBillingGroup: boolean;
}

export interface UnsuccessfulLoginResult {
  type: 'UnsuccessfulLoginResult';
  error: string;
}

export interface SuccessfulLoginResult {
  type: 'SuccessfulLoginResult';
  siteUrl: string;
}

export interface PartialLoginResult {
  type: 'PartialLoginResult';
  token: AuthToken;
}

export interface AnonymousUser {}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly logger =
    inject<LoggerFactory>(LOGGER_FACTORY)('AuthService');

  private http = inject(HttpClient);

  authenticationEndPoint: string = '/Authentication';
  authenticationByCodeEndPoint: string = '/AuthenticationByCode';
  validationEndPoint: string = '/ValidateToken';

  private authStorageService = inject(AuthStorageService);
  private browserService = inject(BrowserService);
  private clientSettingsService = inject(ClientSettingsService);
  private site = inject(MDB_VIRTUAL_SITE);

  constructor() {
    let maybeJwt = null;
    if (this.site.type !== VirtualSiteType.Global) {
      maybeJwt = this.authStorageService.get();
    }

    this.updateStateFromJwt(maybeJwt);
  }

  private readonly authStateService: AuthStateService =
    inject(AuthStateService);

  public get token(): AuthToken | null {
    const maybeEncoded = this.authStorageService.get();

    if (!maybeEncoded) {
      return null;
    }

    let authToken = null;

    try {
      const payload = jwtDecode<MdbJwtPayload>(maybeEncoded);

      authToken = {
        encoded: maybeEncoded,
        payload: payload,
      } as AuthToken;
    } catch (error: unknown) {
      this.logger.error('Failed to decode JWT', undefined, error);
      this.updateStateFromJwt(null);
    }

    if (!authToken) {
      return null;
    }

    if (this.isTokenExpired(authToken)) {
      this.logger.debug('JWT expired', { expiry: authToken.payload.exp });
      this.updateStateFromJwt(null);
    }

    return authToken;
  }

  private isTokenExpired(token: AuthToken): boolean {
    return (token.payload.exp ?? 0) * 1000 < Date.now();
  }

  async login(
    patientId: number,
    clientId: string,
    billingGroup: string,
    dobMonth: number,
    dobDay: number,
    dobYear: number
  ): Promise<
    UnsuccessfulLoginResult | PartialLoginResult | SuccessfulLoginResult
  > {
    try {
      const httpResposne = await firstValueFrom(
        this.http.post<authPostResponse>(
          environment.apiUrl + this.authenticationEndPoint,
          {
            patientId: String(patientId),
            clientId: clientId,
            billingGroup: billingGroup,
            dobMonth: String(dobMonth),
            dobDay: String(dobDay),
            dobYear: String(dobYear),
            siteUrl: this.site.url,
          },
          {
            context: new HttpContext().set(ANONYMOUS, true),
            observe: 'response',
          }
        )
      );

      const response = httpResposne.body as authPostResponse;

      const success = response.isSuccess ?? false;

      if (!success) {
        this.logger.warn('Login Authentication Unsuccessful', {
          error: response.error,
        });
        return {
          type: 'UnsuccessfulLoginResult',
          error: response.error,
        } as UnsuccessfulLoginResult;
      }

      if (response.token && !isNullEmptyOrWhitespace(response.token)) {
        const token = this.decodeJwt(response.token);

        if (!token) {
          const message = 'Invalid Login Token Received';
          this.logger.warn('Login Failed', { error: message });
          return {
            type: 'UnsuccessfulLoginResult',
            error: message,
          } as UnsuccessfulLoginResult;
        }

        if (token.payload.NeedsBillingGroup) {
          // Do not store a token for a partial login. Instead, return it so the caller can use it only to patch a login.
          // This simplifies the AuthService and the authentication flows. It also helps encapsulate the concept of this
          // partial authentication from the rest of the application to simplify the application overall.
          return {
            type: 'PartialLoginResult',
            token: token,
          } as PartialLoginResult;
        }

        const settings =
          await this.clientSettingsService.fetchClientSettingsAsync(token);

        this.updateStateFromJwt(token.encoded, settings.siteUrl);

        return {
          type: 'SuccessfulLoginResult',
          siteUrl: settings.siteUrl,
        } as SuccessfulLoginResult;
      }
    } catch (error: unknown) {
      const message = 'Unknown Login Error';
      this.logger.error(message, undefined, error);
      return {
        type: 'UnsuccessfulLoginResult',
        error: message,
      } as UnsuccessfulLoginResult;
    }

    throw new Error('Unexpected Login Result');
  }

  async patchLogin(
    token: AuthToken,
    billingGroupId: number
  ): Promise<UnsuccessfulLoginResult | SuccessfulLoginResult> {
    let newToken: AuthToken | null;

    try {
      const httpResposne = await firstValueFrom(
        this.http.patch<authPatchResponse>(
          environment.apiUrl + this.authenticationEndPoint,
          {
            billingGroupId: String(billingGroupId),
          },
          {
            context: new HttpContext().set(AUTH_TOKEN_OVERRIDE, token),
            observe: 'response',
          }
        )
      );

      const response = httpResposne.body as authPatchResponse;

      const success = response.isSuccess ?? false;

      if (!success) {
        this.logger.warn('Patch Login Authentication Unsuccessful', {
          error: response.error,
        });
        return {
          type: 'UnsuccessfulLoginResult',
          error: response.error,
        } as UnsuccessfulLoginResult;
      }

      if (!isNullEmptyOrWhitespace(response.newToken)) {
        newToken = this.decodeJwt(response.newToken);

        if (!newToken) {
          const message = 'Invalid Patch Login Token Received';
          this.logger.warn('Login Failed', { error: message });
          return {
            type: 'UnsuccessfulLoginResult',
            error: message,
          } as UnsuccessfulLoginResult;
        }

        const settings =
          await this.clientSettingsService.fetchClientSettingsAsync(newToken);

        this.updateStateFromJwt(newToken.encoded, settings.siteUrl);

        return {
          type: 'SuccessfulLoginResult',
          siteUrl: settings.siteUrl,
        } as SuccessfulLoginResult;
      }
    } catch (error: unknown) {
      const message = 'Unknown Patch Login Error';
      this.logger.error(message, undefined, error);

      return {
        type: 'UnsuccessfulLoginResult',
        error: message,
      } as UnsuccessfulLoginResult;
    }

    throw new Error('Unexpected Patch Login Result');
  }

  async loginWithCode(
    verificationCode: string,
    destination: string,
    dobMonth: number,
    dobDay: number,
    dobYear: number
  ): Promise<UnsuccessfulLoginResult | SuccessfulLoginResult> {
    try {
      const httpResposne = await firstValueFrom(
        this.http.post<authenticationByCodeResponse>(
          environment.apiUrl + this.authenticationByCodeEndPoint,
          {
            verificationCode: verificationCode,
            destination: destination,
            dobMonth: String(dobMonth),
            dobDay: String(dobDay),
            dobYear: String(dobYear),
            siteUrl: this.site.url,
          } as authenticationByCodeRequest,
          {
            context: new HttpContext().set(ANONYMOUS, true),
            observe: 'response',
          }
        )
      );

      const response = httpResposne.body as authenticationByCodeResponse;

      if (httpResposne.status === 200 && response) {
        if (!response.isSuccess) {
          this.logger.warn('Login Failed', { error: response.error });

          return {
            type: 'UnsuccessfulLoginResult',
            error: response.error,
          } as UnsuccessfulLoginResult;
        }

        if (!isNullEmptyOrWhitespace(response.token)) {
          const token = this.decodeJwt(response.token);

          if (!token) {
            const message = 'Invalid Login With Code Token Received';
            this.logger.warn('Login Failed', { error: message });
            return {
              type: 'UnsuccessfulLoginResult',
              error: message,
            } as UnsuccessfulLoginResult;
          }

          const settings =
            await this.clientSettingsService.fetchClientSettingsAsync(token);

          this.updateStateFromJwt(token.encoded, settings.siteUrl);

          return {
            type: 'SuccessfulLoginResult',
            siteUrl: settings.siteUrl,
          } as SuccessfulLoginResult;
        }
      }
    } catch (error: unknown) {
      const message = 'Unknown Login With Code Error';
      this.logger.error(message, undefined, error);

      return {
        type: 'UnsuccessfulLoginResult',
        error: message,
      } as UnsuccessfulLoginResult;
    }

    throw new Error('Unexpected Login Result');
  }

  validateToken(): void {
    if (this.authStateService.status === AuthStatusType.Anonymous) {
      this.logger.warn(
        'Token revalidation requested for unauthenticated user.'
      );
      return;
    }

    this.logger.debug('Token revalidation requested.');

    this.http
      .post<validateTokenResponse>(
        environment.apiUrl + this.validationEndPoint,
        {},
        {
          observe: 'response',
        }
      )
      .subscribe({
        next: (res: HttpResponse<validateTokenResponse>) => {
          if (res.status === 200 && res.body) {
            if (res.body.success && res.body.token) {
              this.updateStateFromJwt(res.body.token);
              this.authStateService.updateLastValidationDate();
              return;
            }

            if (!res.body.success) {
              void this.purgeTokenAndReload('Token revalidate unsuccesful.');
              return;
            }
          }
        },
      });
  }

  async logout(reason: string): Promise<void> {
    try {
      const success = await firstValueFrom(
        this.http.delete<boolean>(
          environment.apiUrl + this.authenticationEndPoint,
          {
            observe: 'response' as const,
          }
        )
      );

      if (!success) {
        this.logger.warn('Revoking Token Failed!');
      }
    } catch (error: unknown) {
      this.logger.error('Logout Failed!', undefined, error);
    } finally {
      await this.purgeTokenAndReload(reason);
    }
  }

  private decodeJwt(maybeJwt: string | null): AuthToken | null {
    if (!maybeJwt) {
      return null;
    }

    let token = null;
    try {
      const payload = jwtDecode<MdbJwtPayload>(maybeJwt);

      token = {
        encoded: maybeJwt,
        payload: payload,
      } as AuthToken;
    } catch (error: unknown) {
      this.logger.error('Failed to decode JWT', undefined, error);
    }

    return token;
  }

  private updateStateFromJwt(
    maybeJwt: string | null,
    siteUrl: string = this.site.url
  ): void {
    function isAuthenticated(token: AuthToken | null): boolean {
      if (isPartiallyAuthenticated(token)) {
        return false;
      }

      return !!token;
    }

    function isPartiallyAuthenticated(token: AuthToken | null): boolean {
      return token?.payload.NeedsBillingGroup || false;
    }

    let token = this.decodeJwt(maybeJwt);

    if (token) {
      if (this.isTokenExpired(token)) {
        this.logger.debug('JWT expired', { expiry: token.payload.exp });
        token = null;
      }
    }

    if (isPartiallyAuthenticated(token)) {
      const message =
        'Attemping to update state from JWT for a partially authenticated user';
      this.logger.error(message, undefined);
      throw new Error(message);
    }

    if (token) {
      if (siteUrl.toLowerCase() === environment.webAppHost.toLowerCase()) {
        throw new Error('Global site does not support authenticated users');
      }

      this.authStorageService.set(token.encoded, siteUrl);
    } else {
      if (siteUrl.toLowerCase() !== environment.webAppHost.toLowerCase()) {
        this.authStorageService.delete();
      }
    }

    const authenticationStatus =
      isAuthenticated(token) && this.site.isSameSite(siteUrl)
        ? AuthStatusType.Authenticated
        : AuthStatusType.Anonymous;

    // Update AuthStateService after storing the token to help avoid race conditions
    // in the app when authentication status changes from Anonymous to Authenticated.
    this.authStateService.updateStatus(authenticationStatus);
  }

  private async purgeTokenAndReload(reason: string) {
    this.updateStateFromJwt(null);

    const success = await this.browserService.reload(reason);

    if (!success) {
      this.logger.warn('Reloading application after logout failed!', undefined);
    }
  }
}
