import { Injectable } from '@angular/core';

import { StateService } from '../../common/state.service';

export enum AuthStatusType {
  Anonymous = 'Anonymous',
  Authenticated = 'Authenticated',
}

export interface AuthState {
  status: AuthStatusType;
  lastUpdateDate: Date;
  lastValidationDate?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class AuthStateService extends StateService<AuthState> {
  constructor() {
    const initialState = {
      status: AuthStatusType.Anonymous,
      lastUpdateDate: new Date(),
    } as AuthState;

    super(initialState);
  }

  public get status(): AuthStatusType {
    return this.select('status')();
  }

  public updateStatus(status: AuthStatusType): void {
    if (this.select('status')() === status) {
      return;
    }

    const state = {
      status: status,
      lastUpdateDate: new Date(),
    } as AuthState;

    this.update(state);
  }

  public updateLastValidationDate(): void {
    this.update({ lastValidationDate: new Date() });
  }
}
