import { inject, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MDB_VIRTUAL_SITE } from '../services/virtual-site/virtual-site.service';

@Component({
  selector: 'app-footer',
  imports: [RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  site = inject(MDB_VIRTUAL_SITE);

  termsOfUseUrl: string =
    this.site.settings.links.termsOfUse ??
    'https://dcsokk5etzbbl.cloudfront.net/Pxp/terms-of-use.html';

  privacyPolicyUrl: string =
    this.site.settings.links.privacyPolicy ??
    'https://dcsokk5etzbbl.cloudfront.net/Pxp/privacy-policy.html';
}
