<details>
  <summary>Dev Panel</summary>

  <div class="details__content">
    <div class="t-heading-small">Session</div>

    <div class="auth-info">
      Status:
      <span
        class="auth-info__state t-bold {{
          this.isAuthenticated() && 'is-authenticated'
        }}">
        {{ this.authStatus() }}
        <br />
      </span>

      Idle:
      <span
        class="auth-info__state t-bold {{
          !this.isIdle() && 'is-authenticated'
        }}">
        {{ this.isIdle() ? 'True' : 'False' }}
        <br />
      </span>

      Updated:
      <span class="auth-info__date t-bold">
        {{ this.lastUpdateDate() | date: 'h:mm:ss a' }}
        <br />
      </span>

      Validated:
      <span class="auth-info__date t-bold">
        @if (this.lastValidationDate()) {
          {{ this.lastValidationDate() | date: 'h:mm:ss a' }}
        } @else {
          Never
        }
        <br />
      </span>

      Last Activity:
      <span class="auth-info__date t-bold">
        {{ this.lastActivityDate() | date: 'h:mm:ss a' }}
        <br />
      </span>
    </div>

    @if (this.isAuthenticated()) {
      <button (click)="onLogoutClick()">Logout</button>
    }

    @if (this.isAuthenticated()) {
      <button (click)="onToggleValidationClick()">
        @if (this.validateRunning === false) {
          Start Auto-validate
        } @else {
          Stop Validating
        }
      </button>
    }

    @if (this.validateRunning === true) {
      @if (this.isAuthenticated()) {
        <span>
          Auto-validating stopping 1 hour from:
          <span class="t-bold">
            {{ this.validateRunningDate | date: 'h:mm a' }}
          </span>
        </span>
      }
    }

    <div class="t-heading-small">Build</div>

    <div class="build-info">
      Version:
      <span class="build-info__version t-bold">
        {{ this.buildInfo.version }}
        <br />
      </span>

      Commit:

      <a href="{{ this.buildInfo.commitUrl }}" class="t-link" target="_blank">
        {{ this.buildInfo.commit }}
        <span class="visually-hidden">(opens in a new window)</span>
        <br />
      </a>
    </div>
  </div>
</details>
