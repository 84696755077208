import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { AuthStatusType } from '../../../services/auth/auth-state.service';
import { AuthService } from '../../../services/auth/auth.service';
import { MDB_AUTH_STATUS } from '../../../services/auth/tokens';

import {
  ANONYMOUS,
  AUTH_TOKEN_OVERRIDE,
  OPTIONALLY_AUTHENTICATED,
} from './tokens';

export const MUTUALLY_EXCLUSIVE_TOKENS = [
  ANONYMOUS,
  AUTH_TOKEN_OVERRIDE,
  OPTIONALLY_AUTHENTICATED,
];

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService);
  const authStatus = inject(MDB_AUTH_STATUS);

  if (
    MUTUALLY_EXCLUSIVE_TOKENS.filter(token => request.context.has(token))
      .length > 1
  ) {
    throw new Error(
      'HTTP Request with mutually exclusive authentication options failed'
    );
  }

  const anonymous = request.context.get(ANONYMOUS);
  const maybeAuthenticate = request.context.get(OPTIONALLY_AUTHENTICATED);

  if (
    anonymous ||
    (authStatus() === AuthStatusType.Anonymous && maybeAuthenticate)
  ) {
    return next(request);
  }

  const token = request.context.get(AUTH_TOKEN_OVERRIDE) ?? authService.token;

  if (!token) return next(request);

  const cloneRequest = request.clone({
    setHeaders: {
      Authorization: token.encoded,
    },
  });

  return next(cloneRequest);
};
