import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  GuardResult,
  Router,
  RouterStateSnapshot,
  UrlCreationOptions,
} from '@angular/router';

import { AuthStatusType } from '../../../services/auth/auth-state.service';
import { MDB_AUTH_STATUS } from '../../../services/auth/tokens';

export const isAuthenticated: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): GuardResult => {
  const authStatus = inject(MDB_AUTH_STATUS)();
  const router = inject(Router);

  if (authStatus === AuthStatusType.Authenticated) {
    return true;
  }

  return router.createUrlTree(['/login'], {
    queryParams: {
      returnUrl: state.url,
    },
  } as UrlCreationOptions);
};
