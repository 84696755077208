import { HttpContextToken } from '@angular/common/http';

import { AuthToken } from '../../../services/auth/models';

export const ANONYMOUS: HttpContextToken<boolean> = new HttpContextToken(
  () => false
);

export const AUTH_TOKEN_OVERRIDE: HttpContextToken<AuthToken | null> =
  new HttpContextToken(() => null);

export const OPTIONALLY_AUTHENTICATED: HttpContextToken<boolean> =
  new HttpContextToken(() => false);
