import { ApplicationConfig, ApplicationRef, Type } from '@angular/core';

import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { Logger } from './core/observability/logger';

/**
 * The AppLoader class orchestrates the application's loading sequence. The loading sequence dynamically builds
 * the needed Angular Providers, Configuration, and Injection Token values for the virtual site and environment where
 * the application launches and ends in bootstrapping the dynamically configured Angular application.
 *
 * The class interacts directly with the DOM using Vanilla JS to display loading and error states before the Angular
 * application is bootstrapped and loaded.
 *
 * @constructor
 * @param bootstrapApplication - A reference to the bootstrapApplication function from @angular/platform-browser.
 *
 * @method load
 * Initiates the application loading sequence, showing a loading indicator if initialization takes longer than a few
 * seconds. It attempts to bootstrap the application and logs the status. If an error occurs, it displays an error
 * message and returns false.
 *
 * @returns {Promise<boolean>} - A promise that resolves to true if the application loads successfully, or false if
 * it fails.
 */
export class AppLoader {
  private readonly LOADING_INDICATOR_DELAY_SECONDS = 2;

  constructor(
    private bootstrapApplication: (
      rootComponent: Type<unknown>,
      options?: ApplicationConfig
    ) => Promise<ApplicationRef>
  ) {}

  async load(): Promise<boolean> {
    function showLoading() {
      const loading = document.getElementById('splash-loading');
      loading!.classList.replace('hidden', 'shown');
    }

    function showApplication() {
      // Uncomment setTimeout to simulate a delay fetching dynamic application configuration.

      // setTimeout(async () => {
      isLoading = false;
      const splash = document.getElementById('splash');
      splash!.classList.replace('shown', 'hidden');
      // }, 15000);
    }

    function showError() {
      isLoading = false;
      const loading = document.getElementById('splash-loading');
      loading!.classList.replace('shown', 'hidden');
      const error = document.getElementById('splash-error');
      error!.classList.replace('hidden', 'shown');
    }

    // We expect the site to load quickly, and to avoid flicker, we only show the loading indicator if the user waits
    // longer than anticipated.
    setTimeout(function () {
      if (isLoading) {
        showLoading();
      }
    }, this.LOADING_INDICATOR_DELAY_SECONDS * 1000);

    const logger = new Logger('Main');
    logger.debug('Started');

    let isLoading = true;

    try {
      const configuration = await AppConfig.buildConfiguration();

      const application = await this.bootstrapApplication(
        AppComponent,
        configuration
      );

      // application.viewCount is read to make await bootstrapApplication(...) above blocking
      logger.debug('Ready', { viewCount: application.viewCount });

      showApplication();
    } catch (error: unknown) {
      logger.error('Failed', {}, error);
      showError();
      return false;
    }

    return true;
  }
}
