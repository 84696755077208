import { computed, inject, Component, OnInit } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';

import { DevOverlayComponent } from './dev-overlay/dev-overlay.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PaperlessBillingModalComponent } from './paperless-billing-modal/paperless-billing-modal.component';
import { AuthStatusType } from './services/auth/auth-state.service';
import { AuthService } from './services/auth/auth.service';
import { MDB_AUTH_STATUS } from './services/auth/tokens';
import { MDB_VIRTUAL_SITE } from './services/virtual-site/virtual-site.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    RouterModule,
    RouterOutlet,
    DevOverlayComponent,
    FooterComponent,
    HeaderComponent,
    PaperlessBillingModalComponent,
  ],
})
export class AppComponent implements OnInit {
  protected authService = inject(AuthService);
  private authStatus = inject(MDB_AUTH_STATUS);
  private site = inject(MDB_VIRTUAL_SITE);

  // Site Data
  title = 'My-Doc-Bill';

  protected isAuthenticated = computed(
    () => this.authStatus() === AuthStatusType.Authenticated
  );

  ngOnInit(): void {
    if (
      window.CSS &&
      window.CSS.registerProperty &&
      this.site.theme.colors.primary
    ) {
      window.CSS.registerProperty({
        name: '--client-color',
        syntax: '*',
        inherits: false,
        initialValue: this.site.theme.colors.primary,
      });
    }
  }
}
