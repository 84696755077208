import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface uspsResponse {
  isSuccess: boolean;
  error: string;
  addressInfo: uspsAddressResponse;
}

export interface uspsAddressResponse {
  firm: string;
  address: uspsAddress;
  additionalInfo: uspsAdditionalInfo;
  corrections: uspsCodes[];
  matches: uspsCodes[];
}

export interface uspsAddress {
  streetAddress: string;
  streetAddressAbbreviation: string;
  secondaryAddress: string;
  cityAbbreviation: string;
  city: string;
  state: string;
  zipCode: string;
  zipPlus4: string;
  urbanization: string;
}

export interface uspsAdditionalInfo {
  deliveryPoint: string;
  carrierRoute: string;
  dpvConfirmation: string;
  dpvCMRA: string;
  business: string;
  centralDeliveryPoint: string;
  vacant: string;
}

export interface uspsCodes {
  code: string;
  text: string;
}

@Injectable({
  providedIn: 'root',
})
export class UspsService {
  private http = inject(HttpClient);

  endPointBase: string = '/Usps';

  validateAddress(formData: unknown): Observable<HttpResponse<uspsResponse>> {
    return this.http.post<uspsResponse>(
      environment.apiUrl + this.endPointBase,
      formData,
      { observe: 'response' }
    );
  }
}
