<dialog
  #successDialog
  class="paperless-billing-modal paperless-billing-modal--success modal">
  <button
    class="modal__close-button button-inline"
    (click)="closeSuccessModal()">
    <app-svg
      icon="#close"
      fill="var(--icon-fill)"
      [inlineSize]="24"
      [blockSize]="24" />
    <span class="visually-hidden">Close</span>
  </button>
  <div class="paperless-billing-modal__header">
    <app-svg
      [icon]="'#success'"
      [blockSize]="50"
      [inlineSize]="50"
      fill="var(--default-theme)">
      <span class="visually-hidden">Close</span>
    </app-svg>
    <h1 class="paperless-billing-modal__header__heading">Confirmed</h1>
  </div>
  <p class="paperless-billing-modal__confirmation t-body">
    Your communication preference has been updated to
    @if (this.type === 'paper') {
      paper statements. Any future statements will now be mailed to the address
      you provided:
      <p class="paperless-billing-modal__confirmation__input t-bold">
        <span>{{ this.inputAddress.address1 }}</span>
        <span>{{ this.inputAddress.address2 }}</span>
        <span>
          {{ this.inputAddress.city }}, {{ this.inputAddress.state }}
          {{ this.inputAddress.zip }}
        </span>
      </p>
    } @else {
      paperless statements. Any future statements will now be sent to the
      @if (this.type === 'email') {
        email address you provided:
        <p class="paperless-billing-modal__confirmation__input t-bold">
          {{ this.inputEmail }}
        </p>
      } @else if (this.type === 'text') {
        phone number you provided:
        <p class="paperless-billing-modal__confirmation__input t-bold">
          {{ this.inputPhoneNumber }}
        </p>
      }
    }
  </p>
  <p class="banner__success__dialog__confirmation t-body">
    Please continue now to the patient portal to view your account.
  </p>
  <p class="paperless-billing-modal__confirmation__note t-body">
    Important note: Your chosen communication preference only applies for new
    notifications about your account. Communication preference changes may take
    up to 48 hours to apply.
  </p>
  <button
    class="paperless-billing-modal__button button"
    (click)="closeSuccessModal()">
    View My Account
  </button>
</dialog>

<dialog
  #billing
  class="paperless-billing-modal modal"
  data-testid="paperless-dialog">
  <!-- Hide ability to close modal if you haven't set a billing preference yet -->
  <!-- Hide the ability to close modal if you have a bad email indicator -->
  @if (
    this.paperlessBillingService.getPaperlessBillingInfo().enrollmentState !==
      'DISCOVERED' &&
    this.paperlessBillingService.getPaperlessBillingInfo().enrollmentState !==
      'NOT ENROLLED' &&
    this.paperlessBillingService.getPaperlessBillingInfo().enrollmentState !==
      '' &&
    this.paperlessBillingService.getPaperlessBillingInfo().emailIndicator !==
      'B' &&
    this.paperlessBillingService.getPaperlessBillingInfo().mobileIndicator !==
      'B'
  ) {
    <button
      class="modal__close-button button-inline"
      (click)="closeBillingModal()">
      <app-svg
        icon="#close"
        fill="var(--icon-fill)"
        [inlineSize]="24"
        [blockSize]="24" />
      <span class="visually-hidden">Close</span>
    </button>
  }
  <header class="paperless-billing-modal__header modal__header">
    <h1 class="paperless-billing-modal__header__heading modal__header__heading">
      Confirm your Consent and Communication Preferences
    </h1>
    <app-svg
      [icon]="'#communications-preferences'"
      [blockSize]="142"
      [inlineSize]="142"
      fill="var(--default-theme)" />
    <p class="paperless-billing-modal__header__body t-body-large">
      How would you like to receive future communication?
    </p>
  </header>
  @if (this.type === 'email' || this.type === 'text') {
    @if (
      this.paperlessBillingService.getPaperlessBillingInfo().enrollmentState !==
      'FEATURE DISABLED'
    ) {
      <div class="paperless-billing-modal__radio-group">
        @if (
          this.paperlessBillingService.getPaperlessBillingInfo().emailSettings
            .offerEmail === true
        ) {
          <label class="paperless-billing-modal__radio-group__label t-body">
            <span>Email</span>
            <input
              class="paperless-billing-modal__radio-group__label__input"
              type="radio"
              name="paperless_contact"
              [checked]="this.type === 'email' ? true : false"
              placeholder="Email"
              (click)="radioSelectEmail()" />
          </label>
        }
        @if (
          this.paperlessBillingService.getPaperlessBillingInfo().emailSettings
            .offerText === true
        ) {
          <label class="paperless-billing-modal__radio-group__label t-body">
            <span>Text</span>
            <input
              class="paperless-billing-modal__radio-group__label__input"
              type="radio"
              name="paperless_contact"
              [checked]="this.type === 'text' ? true : false"
              (click)="radioSelectText()" />
          </label>
        }
      </div>
    }
    @if (
      this.emailForm &&
      this.type === 'email' &&
      this.paperlessBillingService.getPaperlessBillingInfo().enrollmentState !==
        'FEATURE DISABLED' &&
      this.paperlessBillingService.getPaperlessBillingInfo().emailSettings
        .offerEmail === true
    ) {
      <form
        [formGroup]="emailForm"
        class="paperless-billing-modal__form"
        (ngSubmit)="emailSubmit()">
        <!-- Paperless - Email -->
        <div class="paperless-billing-modal__form__inputs">
          <label
            class="paperless-billing-modal__form__inputs__label form__label">
            <span class="visually-hidden">Email Address</span>
            <input
              class="form__label__input form__label__input--large paperless-billing-modal__form__inputs__label__input"
              formControlName="emailAddress"
              data-testid="email-form-emailAddress"
              type="email"
              placeholder="your@email.com"
              required="required" />
          </label>
          @if (
            this.validatedBadEmail &&
            emailForm.get('emailAddress')?.getError('badEmail')
          ) {
            <p
              class="paperless-billing-modal__form__errors"
              data-testid="email-form-error-badEmailAddress">
              {{ this.formErrors.badEmail }}
            </p>
          }
          <label
            class="paperless-billing-modal__form__inputs__label paperless-billing-modal__form__inputs__label--checkbox">
            <input
              formControlName="verifyEmail"
              type="checkbox"
              data-testid="email-form-verifyEmail"
              required="required"
              (click)="checkBadEmail()" />
            <span class="t-body"
              >I have verified that my information is correct</span
            >
          </label>
        </div>
        <p class="paperless-billing-modal__form__tos t-body">
          By clicking below, I'm giving express consent to receive, at this
          email address, emails from
          {{ this.site.settings.entity.name }}
          and its agents and affiliates, to manage my appointments and get
          information about bills and paying for services, for all current and
          future transactions with
          {{ this.site.settings.entity.name }}. Purchase not required. By opting
          into paperless billing, I convey that I agree to the
          <a
            [href]="this.termsOfUseUrl"
            class="t-link"
            target="_blank"
            data-testid="login-terms-of-use-link">
            Terms of Use
            <span class="visually-hidden">(opens in a new window)</span>
          </a>
        </p>
        <div class="paperless-billing-modal__form__buttons">
          <button
            class="paperless-billing-modal__form__buttons__button form__submit__button button"
            type="submit"
            [disabled]="!emailForm.valid"
            data-testid="email-form-submit">
            Opt into Emails
          </button>
        </div>
      </form>
    } @else if (
      this.textForm &&
      this.type === 'text' &&
      this.paperlessBillingService.getPaperlessBillingInfo().emailSettings
        .offerText === true
    ) {
      <!-- Paperless - Phone -->
      <form
        [formGroup]="textForm"
        class="paperless-billing-modal__form"
        (ngSubmit)="textSubmit()">
        <div class="paperless-billing-modal__form__inputs">
          <label
            class="paperless-billing-modal__form__inputs__label form__label">
            <span class="visually-hidden">Phone Number</span>
            <input
              class="form__label__input form__label__input--large paperless-billing-modal__form__inputs__label__input"
              formControlName="phoneNumber"
              type="text"
              data-testid="text-form-phoneNumber"
              inputmode="numeric"
              placeholder="xxx-xxx-xxxx"
              required="required"
              maxlength="12"
              pattern="^([0-9]{3}-[0-9]{3}-[0-9]{4})$" />
          </label>
          @if (
            this.validatedBadMobile &&
            textForm.get('phoneNumber')?.getError('badMobile')
          ) {
            <p
              class="paperless-billing-modal__form__errors"
              data-testid="text-form-error-badMobileAddress">
              {{ this.formErrors.badMobile }}
            </p>
          }
          <label
            class="paperless-billing-modal__form__inputs__label paperless-billing-modal__form__inputs__label--checkbox">
            <input
              formControlName="verifyPhone"
              data-testid="text-form-verifyPhone"
              type="checkbox"
              required="required"
              (click)="checkBadMobile()" />
            <span class="t-body"
              >I have verified that my information is correct</span
            >
          </label>
        </div>
        <p class="paperless-billing-modal__form__tos t-body">
          By clicking below, I'm giving express consent to receive, at the
          number above, automated text messages and prerecorded call from
          {{ this.site.settings.entity.name }}
          and its agents and affiliates, to manage my appointments and get
          information about bills and paying for services, for all current and
          future transactions with
          {{ this.site.settings.entity.name }}. Message and data rates may
          apply. Purchase not required. Reply STOP to any text message to
          opt-out. By opting into paperless billing, I convey that I agree to
          the
          <a
            [href]="this.termsOfUseUrl"
            class="t-link"
            target="_blank"
            data-testid="login-terms-of-use-link">
            Terms of Use
            <span class="visually-hidden">(opens in a new window)</span>
          </a>
        </p>
        <div class="paperless-billing-modal__form__buttons">
          <button
            class="paperless-billing-modal__form__buttons__button form__submit__button button"
            data-testid="text-form-submit"
            [disabled]="!textForm.valid"
            type="submit">
            Opt into Text Messages
          </button>
        </div>
      </form>
    }
    <button
      (click)="selectPaper()"
      class="paperless-billing-modal__button button-inline t-body t-bold t-link">
      I would like to opt into paper billing
    </button>
  } @else if (this.paperForm) {
    <!-- Paper - Address -->
    <form
      [formGroup]="paperForm"
      (ngSubmit)="optOutSubmit()"
      class="paperless-billing-modal__form">
      <div class="paperless-billing-modal__form__address">
        <label
          class="form__label paperless-billing-modal__form__address__address-one">
          <span class="visually-hidden" aria-hidden="true">Address</span>
          <input
            class="form__label__input form__label__input--large paperless-billing-modal__address__input"
            type="text"
            data-testid="paper-form-address1"
            required="required"
            placeholder="Street Address"
            formControlName="address1" />
        </label>
        <label
          class="form__label paperless-billing-modal__form__address__address-two">
          <span class="visually-hidden">Address continued</span>
          <input
            class="form__label__input form__label__input--large paperless-billing-modal__address__input"
            type="text"
            data-testid="paper-form-address2"
            placeholder="Unit Number"
            formControlName="address2" />
        </label>
        <label class="form__label paperless-billing-modal__form__address__zip">
          <span class="visually-hidden">Zip Code</span>
          <input
            class="form__label__input form__label__input--large paperless-billing-modal__address__input"
            type="text"
            inputmode="numeric"
            data-testid="paper-form-zip"
            required="required"
            maxlength="5"
            pattern="[0-9]{5}$"
            placeholder="Zip Code"
            (input)="updateCityState()"
            formControlName="zip" />
        </label>
        <label class="form__label paperless-billing-modal__form__address__city">
          <span class="visually-hidden">City</span>
          <input
            class="form__label__input form__label__input--large paperless-billing-modal__address__input--immutable"
            type="text"
            data-testid="paper-form-city"
            required="required"
            placeholder="City"
            formControlName="city"
            readonly />
        </label>
        <label
          class="form__label paperless-billing-modal__form__address__state">
          <span class="visually-hidden t-body">State</span>
          <input
            class="form__label__input form__label__input--large paperless-billing-modal__address__input--immutable"
            type="text"
            data-testid="paper-form-state"
            required="required"
            placeholder="State"
            formControlName="state"
            maxlength="2"
            readonly />
        </label>
        <label
          class="paperless-billing-modal__form__inputs__label paperless-billing-modal__form__inputs__label--checkbox paperless-billing-modal__form__address__tos">
          <input
            formControlName="verifyAddress"
            type="checkbox"
            data-testid="paper-form-verifyAddress"
            required="required" />
          <span class="form__fieldset__input__verify t-body"
            >I have verified that my information is correct</span
          >
        </label>
      </div>

      <p class="paperless-billing-modal__form__tos t-body">
        By clicking below, I'm giving express consent to receive, paper
        statements regarding my account at the physical address listed above.
        <a
          [href]="this.termsOfUseUrl"
          class="t-link"
          target="_blank"
          data-testid="login-terms-of-use-link">
          Terms of Use
          <span class="visually-hidden">(opens in a new window)</span>
        </a>
      </p>
      <div class="paperless-billing-modal__form__buttons">
        <button
          class="paperless-billing-modal__form__buttons__button button"
          type="submit"
          data-testid="paper-form-submit"
          [disabled]="
            this.completeUspsModal === false ||
            (this.paperForm && !paperForm.valid)
          ">
          Opt into Paper Statements
        </button>
      </div>
    </form>
    @if (this.paperlessBillingService.getPaperlessBillingInfo().isEnabled) {
      <button
        (click)="selectPaperless()"
        class="paperless-billing-modal__button button-inline t-body t-bold t-link">
        I would like to opt into paperless billing
      </button>
    }
  }
  <dialog
    #uspsDialog
    class="paperless-billing-modal paperless-billing-modal--success modal"
    data-testid="usps-dialog">
    <button
      class="modal__close-button button-inline"
      (click)="cancelUspsModal()">
      <app-svg
        icon="#close"
        fill="var(--icon-fill)"
        [inlineSize]="24"
        [blockSize]="24" />
      <span class="visually-hidden">Close</span>
    </button>
    <div class="paperless-billing-modal__header">
      <h1 class="paperless-billing-modal__header__heading">
        Address Verification
      </h1>
    </div>
    <fieldset class="paperless-billing-modal__radio-group">
      <legend
        align="center"
        class="paperless-billing-modal__header__body t-body-large">
        Select the most accurate address
      </legend>
      <label
        class="paperless-billing-modal__radio-address-group__label paperless-billing-modal__radio-address-group__label--divider t-body">
        <input
          class="paperless-billing-modal__radio-address-group__label__input"
          type="radio"
          name="address_verification"
          checked="checked"
          (click)="radioSelectEnteredAddress()"
          data-testid="address-verification-entered-address" />
        <span
          class="paperless-billing-modal__radio-address-group__label__heading t-bold"
          >Entered Address</span
        >
        <span
          class="paperless-billing-modal__radio-address-group__label__address-container">
          <span>{{ this.inputAddress.address1 }}</span>
          <span>{{ this.inputAddress.address2 }}</span>
          <span>{{ this.inputAddress.zip }}</span>
          <span>{{ this.inputAddress.city }},</span>
          <span>{{ this.inputAddress.state }}</span>
        </span>
      </label>
      <label class="paperless-billing-modal__radio-address-group__label t-body">
        <input
          class="paperless-billing-modal__radio-address-group__input"
          type="radio"
          name="address_verification"
          (click)="radioSelectUspsAddress()"
          data-testid="address-verification-entered-usps" />
        <span
          class="paperless-billing-modal__radio-address-group__label__heading t-bold"
          >Suggested Address</span
        >
        <span
          class="paperless-billing-modal__radio-address-group__label__address-container">
          <span>{{ this.uspsAddress.address1 }}</span>
          <span>{{ this.uspsAddress.address2 }}</span>
          <span>{{ this.uspsAddress.zip }}</span>
          <span>{{ this.uspsAddress.city }},</span>
          <span>{{ this.uspsAddress.state }}</span>
        </span>
      </label>
    </fieldset>
    <div class="paperless-billing-modal__address__buttons">
      <button
        class="paperless-billing-modal__address__buttons__cancel button-secondary"
        data-testid="paperless-billing-modal__address__buttons__cancel"
        (click)="cancelUspsModal()">
        Cancel
      </button>
      <button
        class="paperless-billing-modal__address__buttons__use button"
        data-testid="paperless-billing-modal__address__buttons__use"
        (click)="useVerifiedAddress()">
        Verify Address
      </button>
    </div>
  </dialog>
</dialog>
