<nav role="navigation">
  <button
    data-testid="navigation-button"
    class="button-inline nav-toggle"
    aria-expanded="false"
    aria-controls="menu"
    (click)="menuButtonClickHandler()"
    #NavButton>
    <app-svg
      [blockSize]="30"
      [inlineSize]="30"
      [icon]="'#menu'"
      fill="var(--default-theme)" />
    <span class="visually-hidden">Menu</span>
  </button>
  <ul class="nav" id="menu" data-testid="navigation-links" #NavItems>
    @if (this.isAuthenticated()) {
      <li class="nav__item">
        <a
          [routerLink]="['/app/home']"
          data-testid="navigation-home-button"
          class="nav__item__link t-link t-link--nav"
          >Home
        </a>
      </li>
    }
    @if (this.isAuthenticated()) {
      <li class="nav__item">
        <a
          [routerLink]="['/app/demographics']"
          data-testid="navigation-home-button"
          class="nav__item__link t-link t-link--nav"
          >Profile
        </a>
      </li>
    }
    @if (this.isAuthenticated()) {
      <li class="nav__item">
        <!-- <app-banner type="navigation" /> -->
        <button
          class="button-inline nav__item__link t-link t-link--nav"
          (click)="this.paperlessBillingService.openModal()">
          Communication Preferences
        </button>
      </li>
    }
    @if (!this.isAuthenticated()) {
      <li class="nav__item">
        <a
          [routerLink]="['/login']"
          data-testid="navigation-home-button"
          class="nav__item__link t-link t-link--nav"
          >Home
        </a>
      </li>
    }
    <li class="nav__item">
      <a
        [routerLink]="['/support']"
        data-testid="navigation-faq-button"
        class="nav__item__link t-link t-link--nav"
        >FAQs
      </a>
    </li>
    @if (this.site.settings.links.feedback) {
      <li class="nav__item">
        <a
          class="nav__item__link t-link t-link--nav"
          [href]="this.site.settings.links.feedback"
          target="_blank"
          >Feedback</a
        >
      </li>
    } @else {
      <li class="nav__item">
        <a
          [routerLink]="['/feedback']"
          data-testid="navigation-faq-button"
          class="nav__item__link t-link t-link--nav"
          >Feedback</a
        >
      </li>
    }
    @if (this.site.type !== typeGlobal) {
      <li class="nav__item">
        <a
          [routerLink]="['/contact']"
          data-testid="navigation-faq-button"
          class="nav__item__link t-link t-link--nav"
          >Contact Us
        </a>
      </li>
    }
    @if (this.isAuthenticated()) {
      <li class="nav__item">
        <button
          data-testid="navigation-signout-button"
          class="nav__item__link button-inline t-link t-link--nav"
          (click)="onLogoutClick()">
          Sign Out
        </button>
      </li>
    }
  </ul>
</nav>
