import { InjectionToken, inject } from '@angular/core';

import { AuthStateService } from './auth-state.service';
import { AuthService } from './auth.service';

export const MDB_AUTH_STATUS = new InjectionToken('MDB_AUTH_STATUS', {
  providedIn: 'root',
  factory: () => {
    // HACK: This needs refactored to not depend on this side effect, but I'm not sure how to do it without creating a circular reference.
    // AuthService is injected for side effect only, running the AuthService constructor logic that initializes AuthStateService.
    const _ = inject(AuthService);

    return inject(AuthStateService).select('status');
  },
});
