import { A11yModule } from '@angular/cdk/a11y';
import { inject, Component } from '@angular/core';

import { PaperlessBillingService } from '../services/paperless-billing/paperless-billing.service';
import { SvgComponent } from '../svg/svg.component';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
  imports: [A11yModule, SvgComponent],
})
export class BannerComponent {
  paperlessBillingService = inject(PaperlessBillingService);

  openModal() {
    this.paperlessBillingService.openModal();
  }
}
