import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { VirtualSite } from '../../../services/virtual-site/virtual-site';
import { MDB_VIRTUAL_SITE } from '../../../services/virtual-site/virtual-site.service';
import { LOGGER_FACTORY } from '../../observability/provider';

type VirtualSiteAssertion = (site: VirtualSite) => boolean;

export function isEnabled(predicate: VirtualSiteAssertion): CanActivateFn {
  return (_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
    const router = inject(Router);
    const site = inject(MDB_VIRTUAL_SITE);
    const logger = inject(LOGGER_FACTORY)('Routing');

    if (predicate(site)) {
      return true;
    }

    logger.warn('User navigated to disabled route', { route: router.url });
    return router.createUrlTree(['/login']);
  };
}
