import { inject, Component } from '@angular/core';

import { MDB_VIRTUAL_SITE } from '../services/virtual-site/virtual-site.service';

@Component({
  selector: 'app-hero-image',
  templateUrl: './hero-image.component.html',
  styleUrl: './hero-image.component.scss',
})
export class HeroImageComponent {
  site = inject(MDB_VIRTUAL_SITE);

  bannerGraphic =
    this.site.theme.images.banner ?? '/assets/images/green_header.jpg';
}
