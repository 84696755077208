import { inject, Injectable } from '@angular/core';
import { CookieOptions, CookieService, SameSite } from 'ngx-cookie-service';

import { environment } from '../../../environments/environment';
import { LOGGER_FACTORY } from '../../core/observability/provider';
import { BrowserService } from '../browser/browser.service';
import { VirtualSiteType } from '../virtual-site/virtual-site.models';
import { MDB_VIRTUAL_SITE } from '../virtual-site/virtual-site.service';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  private readonly logger = inject(LOGGER_FACTORY)('AuthStorageService');

  private readonly COOKIE_DOMAIN;
  private readonly COOKIE_PATH = '/';
  private readonly COOKIE_TTL_MINUTES = 1;
  private readonly COOKIE_SAME_SITE = 'Strict' as SameSite;
  private readonly COOKIE_SECURE;
  private readonly STORAGE_KEY_PREFIX = 'mdb_token_';

  private cookieService = inject(CookieService);

  private browserService = inject(BrowserService);
  private site = inject(MDB_VIRTUAL_SITE);

  private readonly STORAGE_KEY = this.getFullyQualifiedKey(this.site.url);

  constructor() {
    this.COOKIE_DOMAIN = new URL(
      `${this.browserService.protocol}//${environment.webAppHost}`
    ).hostname;

    this.COOKIE_SECURE = this.browserService.protocol
      .toLowerCase()
      .startsWith('https');

    this.logger.debug('Initialized', {
      cookies: {
        domain: this.COOKIE_DOMAIN,
        path: this.COOKIE_PATH,
        ttlMinutes: this.COOKIE_TTL_MINUTES,
        sameSite: this.COOKIE_SAME_SITE,
        secure: this.COOKIE_SECURE,
      },
      storageKey: this.STORAGE_KEY,
    });

    this.tryInitializeFromCookie();
  }

  delete() {
    if (this.site.type === VirtualSiteType.Global) {
      throw new Error('Global site does not support authenticated users');
    }

    localStorage.removeItem(this.STORAGE_KEY);
  }

  get(): string | null {
    if (this.site.type === VirtualSiteType.Global) {
      throw new Error('Global site does not support authenticated users');
    }

    return localStorage.getItem(this.STORAGE_KEY);
  }

  set(token: string, siteUrl: string) {
    if (this.isGlobalSite(siteUrl)) {
      throw new Error('Global site does not support authenticated users');
    }

    const key = this.getFullyQualifiedKey(siteUrl);

    if (!this.isSameSubdomain(siteUrl)) {
      this.logger.debug('Cookie Set', { key: key });

      this.cookieService.set(key, token, {
        domain: this.COOKIE_DOMAIN,
        expires: new Date(Date.now() + this.COOKIE_TTL_MINUTES * 60 * 1000),
        path: this.COOKIE_PATH,
        secure: this.COOKIE_SECURE,
        sameSite: this.COOKIE_SAME_SITE,
      } as CookieOptions);

      return;
    }

    localStorage.setItem(key, token);
  }

  private getFullyQualifiedKey(siteUrl: string): string {
    return `${this.STORAGE_KEY_PREFIX}${siteUrl.toLowerCase()}`;
  }

  private isGlobalSite(siteUrl: string): boolean {
    return siteUrl === environment.webAppHost;
  }

  private isSameSubdomain(siteUrl: string): boolean {
    const url = new URL(`${this.browserService.protocol}//${this.site.url}`);
    const otherUrl = new URL(`${this.browserService.protocol}//${siteUrl}`);

    return url.hostname === otherUrl.hostname;
  }

  private tryInitializeFromCookie() {
    if (this.site.type === VirtualSiteType.Global) {
      return;
    }

    const token = this.cookieService.get(this.STORAGE_KEY);

    if (token) {
      this.logger.debug('Cookie Found');
      this.cookieService.delete(
        this.STORAGE_KEY,
        this.COOKIE_PATH,
        this.COOKIE_DOMAIN,
        this.COOKIE_SECURE,
        this.COOKIE_SAME_SITE
      );

      this.set(token, this.site.url);
    }
  }
}
