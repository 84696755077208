<header class="header">
  <span class="header__logo">
    <img [src]="this.logoGraphic" alt="My Doc Bill, Powered by Zotec" />
    @if (this.entityName) {
      <span class="header__logo__text" data-testid="header__logo__text">
        {{ this.entityName }}
      </span>
    }
  </span>
  <app-navigation class="header__navigation" />
</header>
@if (this.showHeroImage()) {
  <app-hero-image />
}
