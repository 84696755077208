import { computed, signal, Signal, WritableSignal } from '@angular/core';

export abstract class StateService<T> {
  readonly state: WritableSignal<T>;

  constructor(state: T) {
    this.state = signal(state);
  }

  public select<K extends keyof T>(key: K): Signal<T[K]> {
    return computed(() => this.state()[key]);
  }

  protected set<K extends keyof T>(key: K, data: T[K]) {
    this.state.update(current => ({ ...current, [key]: data }));
  }

  protected update(value: Partial<T>) {
    this.state.update(current => ({ ...current, ...value }));
  }
}
