import { inject, Injectable, resource, OnDestroy } from '@angular/core';
import { interval, Subscription, throttle } from 'rxjs';

import { StateService } from '../../common/state.service';
import { AuthService } from '../auth/auth.service';

export interface IdleState {
  isIdle: boolean;
  lastActivityDate: Date;
}

const USER_ACTIVITY_EVENTS = [
  'keydown',
  'click',
  'mousedown',
  'mousemove',
  'scroll',
];

const IDLE_TIMEOUT = 900; // seconds - 900 for 15mins
const IDLE_CHECK_INTERVAL = 60; // seconds

const IDLE_ERROR =
  'Your session has expired due to inactivity and you have been automatically logged out.';

@Injectable({
  providedIn: 'root',
})
export class IdleService extends StateService<IdleState> implements OnDestroy {
  private idleSubscription?: Subscription;

  private authService = inject(AuthService);

  constructor() {
    super({ isIdle: false, lastActivityDate: new Date() } as IdleState);

    const handler = () => this.updateStatus(false);

    USER_ACTIVITY_EVENTS.forEach(event => {
      window.addEventListener(event, handler);
    });

    resource({
      request: () => ({
        isIdle: this.select('isIdle')(),
      }),
      loader: async ({ request }) => {
        if (request.isIdle) {
          localStorage.setItem('idleMessage', IDLE_ERROR);
          await this.authService.logout('Idle Session Timeout');
        }
      },
    });

    this.startWatching();
  }

  /**
   * Read any stored message that may persist in browser storage when an idle session is logged out and remove any
   * stored message from the browser storage.
   *
   * @returns(string | null) - Returns either the stored message or null if no message exists.
   *
   * @usageNotes
   * The method is intended to be called once from the LoginComponent, which helps guarantee that the idle message is
   * only shown once.
   */
  public takeAndForget(): string | null {
    const message = localStorage.getItem('idleMessage');
    localStorage.removeItem('idleMessage');
    return message;
  }

  private get lastActivityDate() {
    return this.select('lastActivityDate')().getTime();
  }

  private startWatching(): void {
    // Do an initial validation and then validate on an interval. This guarantees the token is valid
    // as soon as possible and avoids race conditions.
    this.authService.validateToken();

    this.updateStatus(false);

    this.idleSubscription = interval(IDLE_CHECK_INTERVAL * 1000)
      .pipe(throttle(() => interval(1000)))
      .subscribe(() => {
        const now = new Date().getTime();

        if (now - this.lastActivityDate > IDLE_TIMEOUT * 1000) {
          this.updateStatus(true);
          return;
        }

        if (now - this.lastActivityDate <= IDLE_CHECK_INTERVAL * 2001) {
          this.authService.validateToken();
        }
      });
  }

  private updateStatus(isIdle: boolean) {
    if (isIdle) {
      this.update({ isIdle: true });
      return;
    }

    this.update({ isIdle: false, lastActivityDate: new Date() });
  }

  private stopWatching() {
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }

  public ngOnDestroy() {
    this.stopWatching();
  }
}
