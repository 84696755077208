@if (
  this.paperlessBillingService.getPaperlessBillingInfo().isEnabled === true &&
  this.paperlessBillingService.getPaperlessBillingInfo().enrollmentState !==
    'ENROLLED'
) {
  <div class="banner">
    <app-svg
      [blockSize]="24"
      [inlineSize]="24"
      fill="var(--white)"
      [icon]="'#leaf'" />
    <p class="banner__text t-body">
      @if (
        this.paperlessBillingService.getPaperlessBillingInfo()
          .enrollmentState === 'NOT ENROLLED' ||
        this.paperlessBillingService.getPaperlessBillingInfo()
          .enrollmentState === 'DISCOVERED'
      ) {
        Be Green. Start paperless billing today!
      } @else {
        You opted out of paperless billing. You will no longer receive our email
        or text message reminders.
      }
    </p>
    <button
      (click)="openModal()"
      class="banner__button button-inline"
      data-testid="paperless-show-dialog">
      <span class="banner__button__text t-body">
        @if (
          this.paperlessBillingService.getPaperlessBillingInfo()
            .enrollmentState === 'NOT ENROLLED' ||
          this.paperlessBillingService.getPaperlessBillingInfo()
            .enrollmentState === 'DISCOVERED'
        ) {
          Learn More
        } @else {
          Start Again
        }
      </span>
    </button>
  </div>
}
