import {
  inject,
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  signal,
} from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { HeroImageComponent } from '../hero-image/hero-image.component';
import { NavigationComponent } from '../navigation/navigation.component';
import { Base64 } from '../services/virtual-site/virtual-site.models';
import { MDB_VIRTUAL_SITE } from '../services/virtual-site/virtual-site.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  imports: [RouterModule, HeroImageComponent, NavigationComponent],
})
export class HeaderComponent implements OnDestroy, OnInit {
  public showHeroImage = signal(false);

  private router = inject(Router);

  private site = inject(MDB_VIRTUAL_SITE);
  private subscription?: Subscription;

  protected logoGraphic: Base64<'png'> | string = this.site.theme.images.logo
    ? this.site.theme.images.logo
    : '/assets/images/logo_transparent.png';

  protected entityName: string = this.site.settings.entity.name ?? '';

  public ngOnInit() {
    this.subscription = this.router.events.subscribe(() => {
      this.showHeroImage.set(this.router.url.startsWith('/login'));
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
